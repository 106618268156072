@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Playfair+Display:wght@700&family=Raleway:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    scroll-behavior: smooth;
    /* font-family: "Raleway", sans-serif; */
    scrollbar-width: thin;
    scrollbar-color: #00a0e2 rgb(33, 33, 33);
  }
  li {
    @apply p-4;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(10, 10, 10, 0.1);
  }
  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, #595959, #0a0a0a);
    border-radius: 10px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
  }
}
